body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Gradient over the background colour */
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 25%,
    rgba(255, 255, 255, 0.15) 75%
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Set the root div to 100% height of the viewport */
#root {
  height: 100vh;
}

/* Set the app div to 100% height of the viewport */
#app {
  height: 100vh;
}
