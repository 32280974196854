.spaceName {
  padding-left: 15px;
}

.textLarge {
  font-size: 1.75rem;
}

.textLarger {
  font-size: 3rem;
}

.spaceStatus {
  font-size: 6rem;
}

.qrCode {
  height: auto;
  width: 325px;
}

.footerRow {
  height: 160px;
}

.footerLogo {
  max-height: 100%;
}
